{
  "2000": {
    "subtitle": "Onde tudo começou",
    "body": "Meus pais nasceram e cresceram no interior de Santa Catarina, onde levavam uma vida de agricultores. Contudo, devido às condições desafiadoras da região, decidiram buscar oportunidades no território gaúcho. Em 2000, chegaram à zona rural de Viamão, onde, em 2000, eu nasci."
  },
  "2005": {
    "subtitle": "Os primeiros passos",
    "body": "Neste ano, dei meus primeiros passos na Escola Estadual de Ensino Médio Francisco Canquerini, próxima à minha casa. Foi nesse colégio onde eu iria estudar durante todo o ensino fundamental e ensino médio."
  },
  "2015": {
    "subtitle": "Ensino médio",
    "body": "Minha vida deu uma guinada significativa ao ingressar no ensino médio. Pasei a estudar pela manhã e, à tarde, trabalhei esporadicamente com meu pai, ganhando experiência valiosa com máquinas agrícolas. Aprendi mecânica, técnicas de cultivo e desenvolvi habilidades em trabalho em equipe."
  },
  "2018": {
    "subtitle": "Faculdade",
    "body": "No segundo semestre de 2018, iniciei a faculdade de Engenharia de Controle e Automação na PUCRS. Essa mudança me trouxe novas perspectivas, e meu primeiro emprego na área de montagem de circuitos para automação residencial em Porto Alegre consolidou minha paixão por tecnologia. Mudei-me para a cidade, compartilhando moradia com um colega."
  },
  "2019": {
    "subtitle": "Pesquisa",
    "body": "Na metade de 2019, deixei meu emprego para ingressar no Grupo de Automação e Controle de Sistemas (GACS) da minha universidade. Lá, envolvi-me em pesquisa de eletrônica de potência, contribuindo para o desenvolvimento de inversores. Essa experiência no GACS foi fundamental para meu amadurecimento, proporcionando aprendizado e conexões valiosas."
  },
  "2020": {
    "subtitle": "Pandemia",
    "body": "Iniciei o ano com a expectativa de um estágio na Petrobras e o lançamento de uma empresa com amigos. No entanto, a pandemia trouxe cortes na verba da Petrobras e também inviabilizou nossa iniciativa empreendedora. Nisso, voltei para casa dos meus pais, onde eu iria passar todo o ano de 2020."
  },
  "2021": {
    "subtitle": "Gerdau",
    "body": "Recebi um e-mail sobre uma oportunidade de estágio na Gerdau, e após um processo seletivo, tornei-me estagiário na Aciaria da Usina Riograndense, voltando para Porto Alegre. Meu foco era introduzir conceitos de indústria 4.0 na minha área, liderando projetos de telemetria em maquinário e explorando dados para obter insights."
  },
  "2022": {
    "subtitle": "Math",
    "body": "No final de 2021, aceitei um convite para trabalhar como Analista de Dados na Math Group. Ingressei como trainee e, após alguns meses, fui promovido para Júnior. Em busca de aprimoramento, iniciei uma certificação em Ciência de Dados pela PUCRS, concluí uma certificação em Liderança e Gestão de Equipe, e comecei a explorar linguagens de programação, desenvolvendo projetos pessoais."
  },
  "2023": {
    "subtitle": "Math + Banco BV",
    "body": "Minha motivação para aprofundar meu conhecimento em tecnologias de nuvem, alinhada à oportunidade surgida na Math para contribuir no Banco BV, impulsionou uma reviravolta significativa em minha trajetória profissional. Durante essa experiência, tive a oportunidade de imergir em tecnologias de ponta e adquirir conhecimentos valiosos no campo do Big Data. Após alguns meses de dedicação e resultados consistentes, fui reconhecido com uma promoção para a posição de profissional pleno."
  }
}
