{
  "Power-BI": {
    "title": "Power BI",
    "value": "90",
    "description": "Introduzi-me ao Power BI em 2021 durante meu tempo na Gerdau, inicialmente criando relatórios ad hoc. Na Math, evoluí para a criação de dashboards mais complexos, trabalhando com estrutura de relações e tratamento de dados para relatórios mais robustos e automatizados."
  },
  "SQL": {
    "title": "SQL",
    "value": "90",
    "description": "Meu primeiro contato com SQL foi na construção de segmentações no Salesforce Marketing Cloud para a CNA, cliente da Math. Aprofundei-me na ferramenta ao atender o Banco BV e utilizar o Spark intensivamente."
  },
  "Databricks": {
    "title": "Databricks (Spark)",
    "value": "85",
    "description": "Em 2023, durante minha atuação no Banco BV pela Math, tive meu primeiro contato com o Databricks. Desde então, desenvolvi diversos projetos, contribuindo para a construção de bases de dados variadas."
  },
  "inglês": {
    "title": "Inglês",
    "value": "80",
    "description": "Iniciei meu aprendizado em inglês no ensino médio, aprofundando-me durante um curso presencial de dois anos e meio. Com a pandemia, intensifiquei meus estudos, obtendo uma nota 8 de 10 na prova oral e escrita de proficiência da PUCRS, além de continuar aprimorando minhas habilidades através de leitura, podcasts, séries e interações com estrangeiros."
  },
  "python": {
    "title": "Python",
    "value": "80",
    "description": "Iniciei minha trajetória com o Python na Math Group, onde o utilizei diariamente para realizar manipulação e análise exploratória de dados por meio da biblioteca Pandas. Durante minha graduação, aprofundei meus conhecimentos na linguagem, construindo aplicações desktop com a biblioteca PyQt. Além disso, desenvolvi algoritmos de webscraping complexos, realizei projetos integrando diversas APIs e implementei diversos algoritmos de tratamento de dados utilizando a biblioteca Spark. Dentre meus projetos mais notáveis, destaco um software de detecção de rostos (disponível em meu GitHub), um algoritmo de webscraping para coleta de dados dos resultados do ENEM e a criação de uma rede neural para detectar a raça de cachorros."
  },
  "Big-Query": {
    "title": "Big Query",
    "value": "70",
    "description": "Também pelo BV, construi vários processos dentro no Google Cloud Platform usando o Big Query."
  },
  "Machine-Learning": {
    "title": "Machine Learning",
    "value": "60",
    "description": "A minha afinidade com o campo do aprendizado de máquina (machine learning) cresceu ao longo da minha jornada de certificação em ciência de dados. Durante esse percurso, desenvolvi modelos de análise de sentimentos a partir de textos, explorei diversas abordagens de regressão e construí uma rede neural para a classificação de raças de cachorros. Na Math, pude criar modelos não supervisionados para a segmentação de diferentes comportamentos do cliente."
  },
  "web-front": {
    "title": "Javascript, HTML e CSS",
    "value": "60",
    "description": "Iniciei projetos front-end por hobby, incluindo este site feito com Bootstrap e animações em Javascript. Além disso, concluí 50 horas no curso 'Curso Web Moderno Completo com JavaScript 2022 + Projetos'."
  },
  "C": {
    "title": "C e C++",
    "value": "50",
    "description": "Durante a graduação, utilizei C para programação de microcontroladores. No C++, desenvolvi programas simples no Qt Creator para realizar tarefas específicas."
  },
  "electron": {
    "title": "Electron",
    "value": "40",
    "description": "Durante a graduação, explorei o Electron ao criar projetos de aplicativos desktop. Destaco um aplicativo que lia sensores e apresentava dados em uma interface intuitiva."
  },
  "Matlab": {
    "title": "Matlab",
    "value": "40",
    "description": "Utilizei o Matlab em minha pesquisa e graduação, aplicando-o na criação de diversos algoritmos matemáticos."
  }
}
