{
  "comunicação": {
    "title": "Comunicação interpessoal",
    "value": "90",
    "description": "Tenho bastante apreço pela troca de conhecimento e por trabalhar em equipe, onde sempre busquei aprender com as pessoas ao meu redor independente da área de atuação. Tive a oportunidade de aprimorar minhas habilidades de comunicação na certificação de Liderança e Gestão de Equipe que fiz na PUCRS. Além disso, a leitura sempre me ajudou bastante a ter o vocabulário para expressar o que eu busco dizer."
  },
  "flexibilidade": {
    "title": "Flexibilidade e adaptabilidade",
    "value": "80",
    "description": "Sempre fui motivado a desafios, enfrentar algum problema novo nunca foi um problema pra mim. Portanto, situações fora do comum é algo em que acredito que eu me sobressaia."
  }
}
